import { httpGet, httpPost, httpDelete } from './httputils';

function createSearchParams(jwt) {
    return new URLSearchParams([['signed_payload_jwt', jwt]]);
}

export async function fetchImpactSetting(jwt) {
    try {
        const response = await httpGet(
            '/api/bigcommerce/impact_setting',
            {},
            createSearchParams(jwt)
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error fetching impact setting:', error.message);
        return { error: error };
    }
}

export async function saveImpactSetting(jwt, impactSettings) {
    try {
        const response = await httpPost(
            '/api/bigcommerce/impact_setting',
            {},
            createSearchParams(jwt),
            impactSettings
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error saving settings:', error);
        return { error: error };
    }
}

export async function fetchInstalledBadges(jwt) {
    try {
        const response = await httpGet(
            '/api/bigcommerce/installed_scripts',
            {},
            createSearchParams(jwt)
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error fetching installed badges:', error.message);
        return { error: error };
    }
}

export async function uninstallSproutBadge(jwt, uuid) {
    try {
        const response = await httpDelete(
            '/api/bigcommerce/installed_script/' + uuid,
            {},
            createSearchParams(jwt)
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error uninstalling badge:', error);
        return { error: error };
    }
}

export async function installSproutCheckoutBadge(jwt) {
    try {
        const response = await httpPost(
            '/api/bigcommerce/badge/checkout',
            {},
            createSearchParams(jwt)
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error installing checkout badge:', error);
        return { error: error };
    }
}

export async function getSproutProductBadgeRenderBackend(jwt) {
    try {
        const response = await httpGet(
            '/api/bigcommerce/badge/checkout',
            {},
            createSearchParams(jwt)
        );
        return { data: response.data };
    } catch (error) {
        console.error(
            'Error fetching bigcommerce product badge:',
            error.message
        );
        return { error: error };
    }
}

export async function loadModalScript(src) {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = src;
        document.head.appendChild(script);
    }
}

export async function loadBadgeCSS(jwt) {
    try {
        const response = await httpGet(
            '/api/bigcommerce/scripts/css/badge_base',
            {},
            createSearchParams(jwt)
        );
        var t = response.data.css;
        // Check if the stylesheet is already loaded
        var isLoaded = false;
        var links = document.getElementsByTagName('link');
        for (var i = 0; i < links.length; i++) {
            if (links[i].id === baseID) {
                isLoaded = true;
                break;
            }
        }
        // If the stylesheet is not loaded, add it to the document head
        if (!isLoaded) {
            var link = document.createElement('style');
            link.innerHTML = t;
            link.id = 'temp_mock_link';
            document.head.appendChild(link);
        }
    } catch (error) {
        console.error(
            'Error loading bigcommerce product badge css:',
            error.message
        );
        return { error: error };
    }
}

export async function saveBilling(jwt, billing) {
    try {
        const response = await httpPost(
            '/api/bigcommerce/billing',
            {},
            createSearchParams(jwt),
            billing
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error saving billing:', error);
        return { error: error };
    }
}

export async function fetchBilling(jwt) {
    try {
        const response = await httpGet(
            '/api/bigcommerce/billing',
            {},
            createSearchParams(jwt)
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error fetching user settings', error);
        return { error: error };
    }
}

export async function cancelBilling(jwt) {
    try {
        const response = await httpDelete(
            '/api/bigcommerce/billing',
            {},
            createSearchParams(jwt)
        );
        return { data: response.data };
    } catch (error) {
        console.error('Error canceling billing:', error);
        return { error: error };
    }
}
