import { css } from 'styled-components';
import { createRGBA } from '../helpers';
export const shadow = {
  floating: css(["border-radius:", ";box-shadow:", ";"], _ref => {
    let {
      theme
    } = _ref;
    return theme.borderRadius.normal;
  }, _ref2 => {
    let {
      theme
    } = _ref2;
    return `0px 2px 12px ${createRGBA(theme.colors.secondary70, 0.2)}`;
  }),
  raised: css(["border-radius:", ";box-shadow:", ";"], _ref3 => {
    let {
      theme
    } = _ref3;
    return theme.borderRadius.normal;
  }, _ref4 => {
    let {
      theme
    } = _ref4;
    return `0px 1px 6px ${createRGBA(theme.colors.secondary70, 0.2)}`;
  })
};