import { css } from 'styled-components';
import { getSpacingStyles } from '../spacings';
export const withPaddings = () => css(["", ";", ";", ";", ";", ";", ";", ";"], _ref => {
  let {
    padding,
    theme
  } = _ref;
  return padding && getSpacingStyles(padding, theme, 'padding');
}, _ref2 => {
  let {
    paddingTop,
    theme
  } = _ref2;
  return paddingTop && getSpacingStyles(paddingTop, theme, 'padding-top');
}, _ref3 => {
  let {
    paddingRight,
    theme
  } = _ref3;
  return paddingRight && getSpacingStyles(paddingRight, theme, 'padding-right');
}, _ref4 => {
  let {
    paddingBottom,
    theme
  } = _ref4;
  return paddingBottom && getSpacingStyles(paddingBottom, theme, 'padding-bottom');
}, _ref5 => {
  let {
    paddingLeft,
    theme
  } = _ref5;
  return paddingLeft && getSpacingStyles(paddingLeft, theme, 'padding-left');
}, _ref6 => {
  let {
    paddingVertical,
    theme
  } = _ref6;
  return paddingVertical && getSpacingStyles(paddingVertical, theme, 'padding-top', 'padding-bottom');
}, _ref7 => {
  let {
    paddingHorizontal,
    theme
  } = _ref7;
  return paddingHorizontal && getSpacingStyles(paddingHorizontal, theme, 'padding-left', 'padding-right');
});
export function excludePaddingProps(props) {
  const {
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingVertical,
    paddingHorizontal,
    ...rest
  } = props;
  return rest;
}