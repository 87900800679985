import { breakpointsOrder } from '@bigcommerce/big-design-theme';
import { css } from 'styled-components';
export const withDisplay = () => css(["", ";"], _ref => {
  let {
    display,
    theme
  } = _ref;
  return display && getDisplayStyles(display, theme, 'display');
});
const getDisplayStyles = (displayProp, theme, cssKey) => {
  if (typeof displayProp === 'object') {
    return getResponsiveDisplay(displayProp, theme, cssKey);
  }
  if (typeof displayProp === 'string' || typeof displayProp === 'number') {
    return getSimpleDisplay(displayProp, cssKey);
  }
  return [];
};
const getSimpleDisplay = (displayProp, cssKey) => css(["", ":", ""], cssKey, displayProp);
const getResponsiveDisplay = (displayProp, theme, cssKey) => {
  const breakpointKeys = Object.keys(displayProp).sort((firstBreakpoint, secondBreakpoint) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  breakpointsOrder.indexOf(firstBreakpoint) -
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  breakpointsOrder.indexOf(secondBreakpoint));

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return breakpointKeys.map(breakpointKey => css(["", "{", " ", "}"], theme.breakpoints[breakpointKey], /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */'', getSimpleDisplay(displayProp[breakpointKey], cssKey)));
};