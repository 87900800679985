import { breakpointsOrder } from '@bigcommerce/big-design-theme';
import { css } from 'styled-components';
export function getSpacingStyles(spacing, theme) {
  for (var _len = arguments.length, spacingKeys = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    spacingKeys[_key - 2] = arguments[_key];
  }
  if (typeof spacing === 'object') {
    return getResponsiveSpacings(spacing, theme, spacingKeys);
  }
  if (typeof spacing === 'string') {
    return getSimpleSpacings(spacing, theme, spacingKeys);
  }
  return css([""]);
}
function isSpacingKey(key, theme) {
  return key in theme.spacing;
}
function getSimpleSpacings(spacing, theme, spacingKeys) {
  return spacingKeys.reduce((acc, spacingKey) => {
    if (isSpacingKey(spacing, theme)) {
      acc[spacingKey] = theme.spacing[spacing];
    } else {
      acc[spacingKey] = spacing;
    }
    return acc;
  }, {});
}
function getResponsiveSpacings(responsiveSpacing, theme, spacingKeys) {
  const breakpointKeys = Object.keys(responsiveSpacing).sort((a, b) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  breakpointsOrder.indexOf(a) -
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  breakpointsOrder.indexOf(b));

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return breakpointKeys.map(breakpointKey => css(["", "{", "}"], theme.breakpoints[breakpointKey], getSimpleSpacings(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  responsiveSpacing[breakpointKey], theme, spacingKeys)));
}