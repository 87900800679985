import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpGet } from '../httputils';

export function OauthConnect({ redirectPath, integrationId }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const queryParams = new URLSearchParams(window.location.search);
        const requestUrl = `/api/bigcommerce/auth`;

        const fetchData = async () => {
            try {
                const response = await httpGet(requestUrl, {}, queryParams);
                if (response.status == 200) {
                    navigate('/', {
                        state: {
                            signedJWTPayload: response.data.signedJWTPayload,
                            redirectPath,
                            integrationId,
                            stateType: 'oauth',
                        },
                    });
                } else {
                    console.error('Request failed: ', response.status);
                }
            } catch (err) {
                if (isMounted) {
                    console.error(err.message);
                    setError(err.message);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Prevent setting state after unmount
        };
    }, [navigate, redirectPath, integrationId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return <div>you shouldn't reach here</div>;
}
