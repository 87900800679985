import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { App } from './components/App';
import { OauthConnect } from './components/OauthConnect';

export default function Router(props) {
    const basePath = '';

    const router = createBrowserRouter([
        {
            path: `${basePath}`,
            element: <App />,
        },
        {
            path: `${basePath}/bc/auth`,
            element: (
                <OauthConnect
                    redirectPath="/bc/auth"
                    integrationId="bigcommerce"
                />
            ),
        },
    ]);

    return <RouterProvider router={router} />;
}
