class Options {
  options = {
    htmlFontSize: 16
  };
  getOptions() {
    return {
      ...this.options
    };
  }
  setOptions(newOptions) {
    this.options = {
      ...this.options,
      ...newOptions
    };
    return this.getOptions();
  }
}
export const themeOptions = new Options();