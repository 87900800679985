import { css } from 'styled-components';
import { remCalc } from '../helpers';
export const createBorder = () => ({
  box: css(["", ";"], _ref => {
    let {
      theme
    } = _ref;
    return `1px solid ${theme.colors.secondary30}`;
  }),
  boxError: css(["", ";"], _ref2 => {
    let {
      theme
    } = _ref2;
    return `1px solid ${theme.colors.danger40}`;
  }),
  none: 'none'
});
export const createBorderRadius = () => ({
  circle: '50%',
  none: 0,
  normal: remCalc(4)
});