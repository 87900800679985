import React, { useState } from 'react';
import {
    Badge,
    Box,
    Flex,
    Form,
    FormGroup,
    FormControlLabel,
    Input,
    Button,
    Modal,
    Text,
} from '@bigcommerce/big-design';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { saveBilling, cancelBilling } from '../backend.js';
// import { Billing } from './Billing.jsx';
import { stripePromise } from '../stripe.js';

export function BillingModal({ signedJWT, billingIsActive, onModalClose }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModalClose = () => {
        setIsModalOpen(false);
        onModalClose();
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleBillingCancel = async () => {
        await cancelBilling(signedJWT);
        onModalClose();
    };

    return (
        <Flex justifyContent="space-between">
            <Box
                style={{
                    flex: '0 0 45%',
                    padding: '18px',
                }}
            >
                <Text bold marginBottom="medium">
                    Enable Billing
                </Text>
                <Text color="secondary50">
                    We'll charge you based on your planting settings below. Each
                    tree costs 33 cents USD.
                </Text>
            </Box>
            <Box
                className="options"
                style={{
                    flex: 1,
                    padding: '18px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Text bold marginBottom="xxLarge">
                    Current Status
                </Text>
                <Flex justifyContent="center">
                    <Badge
                        label={billingIsActive ? 'active' : 'inactive'}
                        variant={billingIsActive ? 'success' : 'danger'}
                    />
                </Flex>
                <Flex justifyContent="flex-end">
                    {billingIsActive && (
                        <Button
                            variant="secondary"
                            onClick={handleBillingCancel}
                        >
                            Disable
                        </Button>
                    )}
                    <Button onClick={handleModalOpen}>
                        {billingIsActive ? 'Update' : 'Setup'}
                    </Button>
                </Flex>
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    closeOnEscKey={true}
                    closeOnClickOutside={true}
                    header="Setup Billing"
                    variant="modal"
                >
                    <Elements stripe={stripePromise}>
                        <Billing
                            signedJWT={signedJWT}
                            setIsModalOpen={setIsModalOpen}
                            onModalClose={handleModalClose}
                        />
                    </Elements>
                </Modal>
            </Box>
        </Flex>
    );
}

export function Billing({ signedJWT, setIsModalOpen, onModalClose }) {
    const stripe = useStripe();
    const elements = useElements();
    const [billingDetails, setBillingDetails] = useState({
        name: '',
        email: '',
    });

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const handleInputChange = event => {
        setBillingDetails({
            ...billingDetails,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: billingDetails,
        });

        if (error) {
            console.error('[error]', error);
        } else {
            try {
                await saveBilling(signedJWT, paymentMethod);
            } catch (error) {
                console.error('Error saving billing details:', error);
            }
        }

        setIsModalOpen(false);
        onModalClose();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        onModalClose();
    };

    return (
        <Form onSubmit={handleSubmit} name="billingForm">
            <FormGroup>
                <Input
                    name="name"
                    value={billingDetails.name}
                    onChange={handleInputChange}
                    label="Full Name"
                    placeholder="Jane Doe"
                    required
                />
            </FormGroup>
            <FormGroup>
                <Input
                    name="email"
                    value={billingDetails.email}
                    onChange={handleInputChange}
                    label="Email"
                    placeholder="jane.doe@example.com"
                    required
                />
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    htmlFor="cardElement"
                    style={{
                        fontWeight: 'bold',
                        display: 'block',
                        marginBottom: '10px',
                    }}
                >
                    Credit Card
                </FormControlLabel>
            </FormGroup>
            <FormGroup>
                <div
                    style={{
                        border: '1px solid #D9DCE9',
                        borderRadius: '4px',
                        padding: '10px',
                        marginTop: '-12px',
                    }}
                >
                    <CardElement
                        id="cardElement"
                        options={CARD_ELEMENT_OPTIONS}
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <Flex
                    justifyContent="flex-end"
                    style={{ marginTop: '20px', marginBottom: '30px' }}
                >
                    <Button
                        variant="subtle"
                        onClick={handleCancel}
                        marginRight="small"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" style={{ padding: '10px 20px' }}>
                        Save Billing Information
                    </Button>
                </Flex>
            </FormGroup>
        </Form>
    );
}
