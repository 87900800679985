import { css } from 'styled-components';
import { getSpacingStyles } from '../spacings';
export const withMargins = () => css(["", ";", ";", ";", ";", ";", ";", ";"], _ref => {
  let {
    margin,
    theme
  } = _ref;
  return margin && getSpacingStyles(margin, theme, 'margin');
}, _ref2 => {
  let {
    marginTop,
    theme
  } = _ref2;
  return marginTop && getSpacingStyles(marginTop, theme, 'margin-top');
}, _ref3 => {
  let {
    marginRight,
    theme
  } = _ref3;
  return marginRight && getSpacingStyles(marginRight, theme, 'margin-right');
}, _ref4 => {
  let {
    marginBottom,
    theme
  } = _ref4;
  return marginBottom && getSpacingStyles(marginBottom, theme, 'margin-bottom');
}, _ref5 => {
  let {
    marginLeft,
    theme
  } = _ref5;
  return marginLeft && getSpacingStyles(marginLeft, theme, 'margin-left');
}, _ref6 => {
  let {
    marginVertical,
    theme
  } = _ref6;
  return marginVertical && getSpacingStyles(marginVertical, theme, 'margin-top', 'margin-bottom');
}, _ref7 => {
  let {
    marginHorizontal,
    theme
  } = _ref7;
  return marginHorizontal && getSpacingStyles(marginHorizontal, theme, 'margin-left', 'margin-right');
});
export function excludeMarginProps(props) {
  const {
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginVertical,
    marginHorizontal,
    ...rest
  } = props;
  return rest;
}