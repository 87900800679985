const baseURL = 'https://treeshop.ngrok.io';
 
/**
 * A utility function for performing GET requests.
 * @param {string} path The path of the API endpoint.
 * @param {Object} headers An object containing any headers for the request.
 * @param {URLSearchParams} params An object containing query parameters.
 * @returns {Promise<Object>} The JSON response from the request.
 */
export async function httpGet(
    path,
    headers = {},
    params = new URLSearchParams()
) {
    params.append('source', 'bigcommerce');
    const requestUrl = `${baseURL}${path}?${params.toString()}`;

    try {
        const response = await fetch(requestUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
        });

        if (response.status != 200 || response.status == 204) {
            return {
                status: response.status,
                data: null,
            };
        }

        var json = await response.json();
        return {
            status: response.status,
            data: json,
        };
    } catch (error) {
        console.error('Fetch error:', error);
        throw error; // Re-throw the error for the calling function to handle
    }
}

/**
 * A utility function for performing POST requests.
 * @param {string} path The path of the API endpoint.
 * @param {Object} headers An object containing any headers for the request.
 * @param {URLSearchParams} params An object containing query parameters.
 * @param {Object} body The JSON body of the request.
 * @returns {Promise<Object>} The JSON response from the request.
 */
export async function httpPost(
    path,
    headers = {},
    params = new URLSearchParams(),
    body = {}
) {
    const requestUrl = `${baseURL}${path}?${params.toString()}`;

    try {
        const response = await fetch(requestUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error(
                `HTTP error! Status: ${response.status}, Error: ${response.error}`
            );
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        throw error; // Re-throw the error for the calling function to handle
    }
}

/**
 * A utility function for performing DELETE requests.
 * @param {string} path The path of the API endpoint.
 * @param {Object} headers An object containing any headers for the request.
 * @param {URLSearchParams} params An object containing query parameters.
 * @returns {Promise<Object>} The JSON response from the request.
 */
export async function httpDelete(
    path,
    headers = {},
    params = new URLSearchParams()
) {
    params.append('source', 'bigcommerce');
    const requestUrl = `${baseURL}${path}?${params.toString()}`;

    try {
        const response = await fetch(requestUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
        });

        if (!response.ok) {
            // If the response is not ok, throw an error
            throw new Error(
                `HTTP error! Status: ${response.status}, Error: ${response.error}`
            );
        }

        // DELETE requests might not return a body, so check if there is a response to parse
        if (response.status !== 204) {
            return await response.json();
        } else {
            return response.ok;
        }
    } catch (error) {
        console.error('Fetch error:', error);
        throw error; // Re-throw the error for the calling function to handle
    }
}
