import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    fetchImpactSetting,
    saveImpactSetting,
    getSproutProductBadgeRenderBackend,
    loadModalScript,
    loadBadgeCSS,
    fetchBilling,
    installSproutCheckoutBadge,
    fetchInstalledBadges,
    uninstallSproutBadge,
} from '../backend.js';
import {
    Box,
    Button,
    Checkbox,
    Form,
    Radio,
    Text,
    GlobalStyles,
    H4,
    Input,
    Flex,
    FormGroup,
    FlexItem,
} from '@bigcommerce/big-design';
import { BillingModal } from './Billing.jsx';

export function App() {
    const location = useLocation();
    let signedJWT = location.state ? location.state.signedJWTPayload : null;
    const queryParams = new URLSearchParams(location.search);
    const signedJWTPayloadQueryParam = queryParams.get('signed_payload_jwt');
    if (signedJWTPayloadQueryParam != null) {
        signedJWT = signedJWTPayloadQueryParam;
    }

    // Billing
    const [billingIsActive, setBillingIsActive] = useState(false);

    const fetchBillingStatus = async () => {
        try {
            const { data: billing, error } = await fetchBilling(signedJWT);
            if (error) {
                throw new Error(`Error fetching billing status: ${error}`);
            }
            setBillingIsActive(billing.billing.status === 'active');
        } catch (error) {
            console.error(error);
        }
    };

    const onModalClose = async () => {
        await fetchBillingStatus(); // Fetch billing status after the modal closes
    };

    // Planting options
    const [plantingOption, setPlantingOption] = useState('per_order');
    const [numberOfTrees, setNumberOfTrees] = useState(1);
    const [minOrderValue, setMinOrderValue] = useState('');
    const [minOrderEnabled, setMinOrderEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');

    const fetchImpactSettings = async () => {
        try {
            const { data: impactData, error } =
                await fetchImpactSetting(signedJWT);
            if (error) {
                throw new Error(`Error fetching impact settings: ${error}`);
            }
            if (impactData) {
                setPlantingOption(
                    impactData.selectedTreePlantingOption || 'per_order'
                );
                setNumberOfTrees(impactData.numTrees || 1);
                setMinOrderValue(
                    impactData.thresholdAmt
                        ? (impactData.thresholdAmt / 100).toString()
                        : ''
                );
                setMinOrderEnabled(impactData.thresholdAmt > 0);
            }
        } catch (error) {
            console.error(error);
            setSubmitError(error.toString());
        }
    };

    const handlePlantingOptionChange = event => {
        setPlantingOption(event.target.value);
        if (event.target.value == 'per_product') {
            setMinOrderValue('');
            setMinOrderEnabled(false);
        }
    };

    const handleNumberOfTreesChange = event => {
        setNumberOfTrees(event.target.value);
    };

    const handleNumberOfTreesBlur = () => {
        // When input loses focus, if the value is empty or less than 1, reset it to 1.
        if (numberOfTrees === '' || parseInt(numberOfTrees) < 1) {
            setNumberOfTrees(1);
        }
    };

    const handleMinOrderValueChange = event => {
        const value = event.target.value.toString();
        setMinOrderValue(value);
    };

    const handleMinOrderEnabledChange = () => {
        setMinOrderEnabled(!minOrderEnabled);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setSubmitError('');

        const { data, error } = await updateImpactSetting();

        setIsLoading(false);
        if (error) {
            setSubmitError(error);
        }
        getSproutProductBadgeRender();
    };

    const updateImpactSetting = async () => {
        const impactSettings = {
            Type: 'tree_planting',
            SelectedTreePlantingOption: plantingOption,
            NumTrees: parseInt(numberOfTrees, 10), // Convert string to integer
            ThresholdAmt: minOrderEnabled
                ? parseInt(minOrderValue, 10) * 100
                : 0, // Convert string to integer if minOrderEnabled is true
        };

        try {
            const { data, error } = await saveImpactSetting(
                signedJWT,
                impactSettings
            );
            if (!error) {
                getSproutProductBadgeRender();
            }
            return { data, error };
        } catch (error) {
            console.error('Error updating impact setting:', error);
            return { error };
        }
    };

    // Badge rendering
    const [badgeRender, setBadgeRender] = React.useState(null);

    const fetchBadgeRender = async () => {
        try {
            await loadBadgeCSS(signedJWT);
            await loadModalScript(
                'https://sprout-app.thegoodapi.com/app/badges/product_modal_script'
            );
            const badgeRenderData =
                await getSproutProductBadgeRenderBackend(signedJWT);
            if (!badgeRenderData || !badgeRenderData.data) {
                setBadgeRender(
                    '<div> Setup your app settings, before seeing what the badge will look like </div>'
                );
                return;
            }
            setBadgeRender(badgeRenderData.data.html);
        } catch (error) {
            console.error(error);
        }
    };

    async function getSproutProductBadgeRender() {
        await loadBadgeCSS(signedJWT);
        await loadModalScript(
            'https://sprout-app.thegoodapi.com/app/badges/product_modal_script'
        );
        const badgeRender = await getSproutProductBadgeRenderBackend(signedJWT);
        if (badgeRender == null || badgeRender.data == null) {
            setBadgeRender(
                '<div> Setup your app settings, before seeing what the badge will look like </div>'
            );
            return;
        }
        setBadgeRender(badgeRender.data.html);
    }

    useEffect(() => {
        initializeSettings();
    }, []);

    const initializeSettings = async () => {
        await fetchBillingStatus();
        await fetchImpactSettings();
        await fetchBadgeRender();
    };

    // Badge installation
    const [installedBadges, setInstalledBadges] = useState([]);
    const [badgeInstallMessage, setBadgeInstallMessage] = useState('');
    const [isCheckoutBadgeInstalled, setIsCheckoutBadgeInstalled] =
        useState(false);

    const fetchBadges = async () => {
        const { data, error } = await fetchInstalledBadges(signedJWT);
        if (error) {
            console.error('Error fetching installed badges:', error);
        } else {
            setInstalledBadges(data || []);
            const checkoutBadgeInstalled = data.some(
                badge => badge.name === 'Checkout'
            );
            setIsCheckoutBadgeInstalled(checkoutBadgeInstalled);
        }
    };

    useEffect(() => {
        fetchBadges();
    }, []);

    const installCheckoutBadge = async () => {
        try {
            const { data, error } = await installSproutCheckoutBadge(signedJWT);
            if (error) {
                setBadgeInstallMessage(
                    '<div style="color: red;">Failed to install checkout badge</div>'
                );
            } else {
                setBadgeInstallMessage(
                    '<div style="color: green;">Checkout badge installed successfully!</div>'
                );
                fetchBadges(); // Re-fetch badges after successful installation
            }
        } catch (error) {
            console.error(error);
        }
    };

    const uninstallCheckoutBadge = async () => {
        const checkoutBadge = installedBadges.find(
            badge => badge.name === 'Checkout'
        );
        if (checkoutBadge) {
            const { data, error } = await uninstallSproutBadge(
                signedJWT,
                checkoutBadge.uuid
            );
            if (error) {
                console.error('Error uninstalling checkout badge:', error);
                setBadgeInstallMessage(
                    '<div style="color: red;">Failed to uninstall checkout badge</div>'
                );
            } else {
                setBadgeInstallMessage(
                    '<div style="color: green;">Checkout badge uninstalled successfully!</div>'
                );
                fetchBadges(); // Re-fetch badges after successful uninstallation
            }
        } else {
            console.error('Checkout badge not found');
            setBadgeInstallMessage(
                '<div style="color: red;">Checkout badge not found</div>'
            );
        }
    };

    // UI Components

    return (
        <Box
            className="App"
            style={{
                width: '100vw',
                minHeight: '100vh',
                backgroundColor: '#eff0f3',
                borderLeft: '12px solid #e8e8e8',
                padding: '18px',
            }}
        >
            <GlobalStyles />
            <Flex
                justifyContent="space-between"
                flexDirection="column"
                flexGap="20px"
            >
                <BillingModal
                    signedJWT={signedJWT}
                    billingIsActive={billingIsActive}
                    onModalClose={onModalClose}
                />
                <Flex justifyContent="space-between">
                    <Box
                        style={{
                            flex: '0 0 45%',
                            padding: '18px',
                        }}
                    >
                        <Text bold marginBottom="medium">
                            When do you want to plant trees
                        </Text>
                        <Text color="secondary50">
                            You can choose to plant trees on every product sold,
                            or when the order reaches your minimum order value.
                        </Text>
                    </Box>

                    <Box
                        className="options"
                        style={{
                            flex: 1,
                            padding: '18px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Text bold marginBottom="xxLarge">
                            When to plant trees
                        </Text>
                        <Form>
                            <FormGroup>
                                <Radio
                                    label="Plant trees for every product sold"
                                    description="e.g plant 1 tree for every *quantity* of product sold"
                                    value="per_product"
                                    checked={plantingOption === 'per_product'}
                                    onChange={handlePlantingOptionChange}
                                />
                                <Radio
                                    label="Plant trees for every order"
                                    description="e.g plant 1 tree when the order is above $10"
                                    value="per_order"
                                    checked={plantingOption === 'per_order'}
                                    onChange={handlePlantingOptionChange}
                                />
                            </FormGroup>

                            <Box style={{ paddingLeft: '29px' }}>
                                <Flex flexDirection="column">
                                    <FlexItem
                                        flexBasis="100%"
                                        marginBottom="xSmall"
                                    >
                                        <label>
                                            Set the number of trees to plant
                                            with each order
                                            <span style={{ color: 'red' }}>
                                                *
                                            </span>
                                        </label>
                                    </FlexItem>
                                    <FlexItem
                                        flexBasis="100%"
                                        marginBottom="xLarge"
                                    >
                                        <FormGroup>
                                            <Input
                                                value={numberOfTrees.toString()} // Make sure the value is a string
                                                onChange={
                                                    handleNumberOfTreesChange
                                                }
                                                onBlur={handleNumberOfTreesBlur}
                                                min={1}
                                                required
                                            />
                                        </FormGroup>
                                    </FlexItem>

                                    <FlexItem
                                        flexBasis="100%"
                                        marginBottom="large"
                                    >
                                        <FormGroup>
                                            <Checkbox
                                                label="Set minimum order value (optional)"
                                                checked={minOrderEnabled}
                                                onChange={
                                                    handleMinOrderEnabledChange
                                                }
                                            />
                                        </FormGroup>
                                    </FlexItem>

                                    {minOrderEnabled && (
                                        <Flex flexDirection="column">
                                            <FlexItem
                                                flexBasis="100%"
                                                marginBottom="xSmall"
                                            >
                                                <label>
                                                    Only plant trees when order
                                                    exceeds ($USD){' '}
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                            </FlexItem>
                                            <FlexItem
                                                flexBasis="100%"
                                                marginBottom="xSmall"
                                            >
                                                <FormGroup>
                                                    <Input
                                                        value={minOrderValue}
                                                        onChange={e =>
                                                            handleMinOrderValueChange(
                                                                e
                                                            )
                                                        }
                                                        min={0}
                                                    />
                                                </FormGroup>
                                            </FlexItem>
                                        </Flex>
                                    )}
                                    <FlexItem
                                        alignSelf="flex-end"
                                        marginTop="xLarge"
                                    >
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Saving...' : 'Save'}
                                        </Button>
                                    </FlexItem>
                                </Flex>
                            </Box>
                        </Form>
                        {submitError && (
                            <div style={{ color: 'red' }}>{submitError}</div>
                        )}
                    </Box>
                </Flex>
                <Flex justifyContent="space-between">
                    <Box
                        style={{
                            flex: '0 0 45%',
                            padding: '18px',
                        }}
                    >
                        <Flex flexDirection="column">
                            <Text bold marginBottom="xxLarge">
                                Install Marketing Assets
                            </Text>
                            <Box marginBottom="large">
                                <H4>Checkout Badge</H4>
                                <Text color="secondary50">
                                    This can be added to your product page
                                </Text>
                            </Box>
                            <Box>
                                <Text bold color="secondary60">
                                    Want a different language?
                                </Text>
                            </Box>
                            <Text color="secondary50">
                                Please email us the details of the updated copy
                                at saif@thegoodapi.com and we'll enable it for
                                you.
                            </Text>
                        </Flex>
                    </Box>
                    <Box
                        marginBottom="medium"
                        marginTop="xxxLarge"
                        style={{
                            flex: 1,
                            padding: '54px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection="column"
                            flexGap="8px"
                        >
                            <Box
                                marginLeft="5px"
                                dangerouslySetInnerHTML={{
                                    __html: badgeRender,
                                }}
                            />
                            {!isCheckoutBadgeInstalled ? (
                                <Button onClick={installCheckoutBadge}>
                                    Install Checkout Badge
                                </Button>
                            ) : (
                                <Button
                                    variant="secondary"
                                    onClick={uninstallCheckoutBadge}
                                >
                                    Uninstall Checkout Badge
                                </Button>
                            )}
                            <Box
                                dangerouslySetInnerHTML={{
                                    __html: badgeInstallMessage,
                                }}
                            />
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
}
