import { breakpointsOrder } from '@bigcommerce/big-design-theme';
import { css } from 'styled-components';
export const withFlexedContainer = () => css(["", ";", ";", ";", ";", ";", ";", ";", ";"], _ref => {
  let {
    alignContent,
    theme
  } = _ref;
  return alignContent && getFlexedStyles(alignContent, theme, 'align-content');
}, _ref2 => {
  let {
    alignItems,
    theme
  } = _ref2;
  return alignItems && getFlexedStyles(alignItems, theme, 'align-items');
}, _ref3 => {
  let {
    flexDirection,
    theme
  } = _ref3;
  return flexDirection && getFlexedStyles(flexDirection, theme, 'flex-direction');
}, _ref4 => {
  let {
    flexGap,
    theme
  } = _ref4;
  return flexGap && getFlexedStyles(flexGap, theme, 'gap');
}, _ref5 => {
  let {
    flexColumnGap,
    theme
  } = _ref5;
  return flexColumnGap && getFlexedStyles(flexColumnGap, theme, 'column-gap');
}, _ref6 => {
  let {
    flexRowGap,
    theme
  } = _ref6;
  return flexRowGap && getFlexedStyles(flexRowGap, theme, 'row-gap');
}, _ref7 => {
  let {
    flexWrap,
    theme
  } = _ref7;
  return flexWrap && getFlexedStyles(flexWrap, theme, 'flex-wrap');
}, _ref8 => {
  let {
    justifyContent,
    theme
  } = _ref8;
  return justifyContent && getFlexedStyles(justifyContent, theme, 'justify-content');
});
export const withFlexedItems = () => css(["", ";", ";", ";", ";", ";"], _ref9 => {
  let {
    alignSelf,
    theme
  } = _ref9;
  return alignSelf && getFlexedStyles(alignSelf, theme, 'align-self');
}, _ref10 => {
  let {
    flexBasis,
    theme
  } = _ref10;
  return flexBasis && getFlexedStyles(flexBasis, theme, 'flex-basis');
}, _ref11 => {
  let {
    flexGrow,
    theme
  } = _ref11;
  return typeof flexGrow !== 'undefined' && getFlexedStyles(flexGrow, theme, 'flex-grow');
}, _ref12 => {
  let {
    flexOrder,
    theme
  } = _ref12;
  return typeof flexOrder !== 'undefined' && getFlexedStyles(flexOrder, theme, 'order');
}, _ref13 => {
  let {
    flexShrink,
    theme
  } = _ref13;
  return typeof flexShrink !== 'undefined' && getFlexedStyles(flexShrink, theme, 'flex-shrink');
});
const getFlexedStyles = (flexedProp, theme, cssKey) => {
  if (typeof flexedProp === 'object') {
    return getResponsiveFlex(flexedProp, theme, cssKey);
  }
  if (typeof flexedProp === 'string' || typeof flexedProp === 'number') {
    return getSimpleFlex(flexedProp, cssKey);
  }
  return [];
};
const getSimpleFlex = (flexedProp, cssKey) => css(["", ":", ""], cssKey, flexedProp);
const getResponsiveFlex = (flexedProp, theme, cssKey) => {
  const breakpointKeys = Object.keys(flexedProp).sort((firstBreakpoint, secondBreakpoint) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  breakpointsOrder.indexOf(firstBreakpoint) -
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  breakpointsOrder.indexOf(secondBreakpoint));

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return breakpointKeys.map(breakpointKey => css(["", "{", " ", "}"], theme.breakpoints[breakpointKey], /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */'', getSimpleFlex(flexedProp[breakpointKey], cssKey)));
};