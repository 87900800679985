export const breakpointValues = {
  mobile: `0px`,
  tablet: `720px`,
  desktop: `1025px`
};
export const breakpoints = {
  mobile: `@media (min-width: ${breakpointValues.mobile})`,
  tablet: `@media (min-width: ${breakpointValues.tablet})`,
  desktop: `@media (min-width: ${breakpointValues.desktop})`
};
export const breakpointsOrder = ['mobile', 'tablet', 'desktop'];